import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M20.3536 7.64649C20.1583 7.45123 19.8417 7.45123 19.6464 7.64649L12 15.2929L4.35355 7.64649C4.15829 7.45123 3.84171 7.45123 3.64645 7.64649C3.45118 7.84176 3.45118 8.15834 3.64645 8.3536L12 16.7072L20.3536 8.3536C20.5488 8.15834 20.5488 7.84176 20.3536 7.64649Z"
    }, null, -1)
  ])))
}
export default { render: render }